import React, { useState, useEffect } from "react";
import classnames from "classnames";
import getConfig from 'next/config';
import "./StickyWhatsapp.module.scss";

const StickyWhatsapp = () => {
  const [showSticky, setShowSticky] = useState(false);
  const { TSO_PHONE_NUMBER, WHATSAPP_NUMBER } = getConfig().publicRuntimeConfig;

  const redirectWhatsapp = () => {
    const text = 'Halo Lifepal, saya memiliki pertanyaan tentang produk asuransi';

    const WhatsAppAPI = `https://api.whatsapp.com/send/?phone=${WHATSAPP_NUMBER}&text=${encodeURIComponent(text)}&app_absent=0`;
    window.location.href = WhatsAppAPI;
  }

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setShowSticky(true);
    } else {
      setShowSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [showSticky]);


  return (
    <div className={classnames('stickyWhatsapp', showSticky && 'stickyWhatsapp_show')}>
        <span>Customer Service</span>
        <div
          className={classnames('stickyWhatsapp_img', 'gtm_whatsapppopup_buttonclick')}
          title="Call Me Bantuan">
          <a href={`tel:${TSO_PHONE_NUMBER}`}>
            <img alt="call me" loading="lazy" src="/static/images/icons/call-me-round-white.svg" width="32px" height="32px"/>
          </a>
        </div>
        <div className={'stickyWhatsapp_divider'}></div>
        <div
          className={classnames('stickyWhatsapp_img', 'gtm_whatsapppopup_buttonclick')}
          title="Whatsapp Bantuan"
          onClick={() => redirectWhatsapp()}
        >
        
          <img alt="whatsapp" loading="lazy" src="/static/images/icons/whatsapp-round-white.svg" width="32px" height="32px"/>
        </div>
    </div>
  );
};

export default StickyWhatsapp;
